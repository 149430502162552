import { Fragment, useState } from "react";
import Markdown from "react-markdown";
import { Dialog, Transition } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { termsAndConditions } from "../utils/termsAndConditions";
import { useAcceptTermsAndConditionsMutation } from "../graphql/generated/graphql";
import Spinner from "./Spinner";
import { reloadSession } from "../utils/session";

export type TTermsModalState = {
  open?: boolean;
  terms?: string | null;
};

export default function TermsModal({
  modalState,
  setModalState,
}: {
  modalState: TTermsModalState;
  setModalState: (modalState?: TTermsModalState) => void;
}) {
  const [error, setError] = useState<string | null>(null);
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;

  const [acceptTerms, { loading: accepting }] =
    useAcceptTermsAndConditionsMutation({
      onCompleted() {
        onClose();
        reloadSession();
      },
      onError(e) {
        setError(
          "Hubo un error al aceptar los terminos y condiciones, por favor intentalo de nuevo o contacta a soporte."
        );
      },
    });

  function onClose() {
    setModalState({ open: false, terms: null });
  }

  function onAccept() {
    acceptTerms({
      variables: {
        _id: user.id,
        type: modalState.terms,
      },
    });
  }

  return (
    <Transition.Root show={modalState.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => null} static>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 text-gray-700"
                    >
                      Antes de continuar, aceptá nuestros terminos y condiciones
                      de uso actualizados al{" "}
                      {termsAndConditions[modalState.terms]?.version}
                    </Dialog.Title>

                    <div className="mt-3 max-h-[300px] overflow-scroll border border-gray-300 rounded-md p-4 text-gray-700 text-justify prose prose-indigo prose-md">
                      <Markdown>
                        {termsAndConditions[modalState.terms]?.content}
                      </Markdown>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-1">
                  <button
                    type="button"
                    className="inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 disabled:hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm mt-1 sm:mt-0 disabled:opacity-70"
                    disabled={accepting}
                    onClick={onAccept}
                  >
                    {accepting ? <Spinner className="h-5 w-5" /> : null}
                    Aceptar
                  </button>
                </div>
                {error ? (
                  <div className="mt-5 sm:mt-6">
                    <div className="mt-3 text-red-500 text-sm">{error}</div>
                    <button
                      type="button"
                      className="mt-2 inline-flex w-full justify-center rounded-md border border-gray-300 hover:border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-600 shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:text-sm"
                      onClick={onClose}
                    >
                      Cerrar
                    </button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
