declare var GDataCollector: any;

export function initGDataCollector() {
  let collector = null;
  GDataCollector.init(
    process.env.NEXT_PUBLIC_GDATA_COLLECTOR_ENV,
    null,
    function (result) {
      collector = result;
      collector.setupCallback({
        // fires when collection has finished
        "collect-end": function (params) {
          console.log("collection has ended");
          global.GDataCollectorSession = params.MercSessId;
        },
        // fires when collection has started.
        "collect-begin": function () {
          console.log("collection has started");
        },
      });
      collector.collectData();
    }
  );
}
