import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type HistoricPrice {
    _id: ID!
    title: String!
    brand: String!
    model: String!
    year: Int
    basePriceCRC: Float!
    basePriceUSD: Float!
    priceCRCMinus5Percent: Float!
    priceUSDMinus5Percent: Float!
    fuel: String
    cc: Int
    transmission: String
    plate: Int
    category: String
    province: String
    img: String
    # PROPERTIES HERE
  }

  type ModelAvgPriceResponse {
    avgPriceCRC: Float
    minPriceCRC: Float
    maxPriceCRC: Float
    avgPriceUSD: Float
    minPriceUSD: Float
    maxPriceUSD: Float
    category: String
  }

  type ModelYearlyAvgMeta {
    x: Float
    y: Float
  }

  type ModelYearlyAvgResponse {
    results: [ModelYearlyAvgMeta]
  }

  extend type Query {
    getHistoricPrice(
      _id: ID! # PROPERTIES HERE
    ): HistoricPrice
    getHistoricPrices: [HistoricPrice]
    # BOILERPLATER_INJECT_QUERY
    getModelYearlyAvg(brand: String!, model: String!): ModelYearlyAvgResponse
    getModelsAutocomplete(
      query: String
      brand: String! # PROPERTIES HERE
    ): [HistoricPrice]
    getModelAvgPrice(
      # PROPERTIES HERE
      title: String
      brand: String!
      model: String!
      year: Int!
    ): ModelAvgPriceResponse
  }

  extend type Mutation {
    addHistoricPrice(
      # PROPERTIES HERE
      title: String!
    ): HistoricPrice
    updateHistoricPrice(
      _id: ID! # PROPERTIES HERE
    ): HistoricPrice
    deleteHistoricPrice(_id: ID!): HistoricPrice
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
