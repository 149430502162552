import "../styles/globals.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Script from "next/script";
import { SessionProvider, useSession } from "next-auth/react";
import { GoogleTagManager } from "@next/third-parties/google";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider } from "@apollo/client";
import { setUser } from "@sentry/nextjs";
import NextNProgress from "nextjs-progressbar";
import * as gtag from "../lib/gtag";
import Auth from "../components/Auth";
import Toast from "../components/Toast";
import { ToastProvider } from "../context/toast";
import { TermsProvider } from "../context/terms";
import { useApollo } from "../graphql/client";
import { initGDataCollector } from "../utils/GDataCollector";
import { DiscountProvider } from "../context/DiscountsContext";

function SentryUserManager({ children }: { children: React.ReactNode }) {
  const { data } = useSession();
  const { user } = data || {};

  useEffect(() => {
    if (user) {
      setUser({
        id: user.id ?? undefined,
        email: user.email ?? undefined,
      });
    } else {
      setUser(null);
    }
  }, [user]);

  return <>{children}</>;
}

function IncompleteUserManager({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { data, status } = useSession();
  const { user } = data || {};

  useEffect(() => {
    const incompleteUser =
      !user?.firstName ||
      !user?.lastName ||
      !user?.secondLastName ||
      !user?.primaryPhone;
    if (
      incompleteUser &&
      status === "authenticated" &&
      router.pathname !== "/politica-de-privacidad" &&
      router.pathname !== "/terminos-y-condiciones"
    ) {
      router.push(`/new-user`);
    }
  }, [user]);

  return <>{children}</>;
}

function MyApp({
  Component,
  pageProps: { session, initialApolloState, ...pageProps },
}) {
  const isTouchDevice =
    typeof window !== "undefined" && "ontouchstart" in window;

  const router = useRouter();
  const apolloClient = useApollo(initialApolloState);

  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
      <Script
        src="https://static.greenpay.me/collector/1.0.1/GDataCollector.min.js"
        strategy="lazyOnload"
        onLoad={() => {
          initGDataCollector();
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {
          page_path: window.location.pathname,
          });
          `,
        }}
      />
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
          fbq('track', 'PageView');
          `,
        }}
      />

      <noscript>
        <Image
          height="1"
          width="1"
          alt=""
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>

      <SessionProvider session={session}>
        <SentryUserManager>
          <IncompleteUserManager>
            <ApolloProvider client={apolloClient}>
              <DndProvider
                backend={isTouchDevice ? TouchBackend : HTML5Backend}
                options={{
                  ignoreContextMenu: true,
                }}
              >
                <DiscountProvider>
                  <TermsProvider>
                    <ToastProvider>
                      <NextNProgress color="#f97316" />
                      <Auth auth={Component.auth}>
                        {getLayout(<Component {...pageProps} />)}
                      </Auth>
                      <Toast />
                    </ToastProvider>
                  </TermsProvider>
                </DiscountProvider>
              </DndProvider>
            </ApolloProvider>
          </IncompleteUserManager>
        </SentryUserManager>
      </SessionProvider>
    </>
  );
}

// function withMaintenance(App) {
//   function MaintenanceApp({ Component, pageProps, ...props }: AppProps) {
//     const router = useRouter();
//     if (
//       process.env.NEXT_PUBLIC_MAINTENANCE === "true" &&
//       router.pathname !== "/politica-de-privacidad" &&
//       router.pathname !== "/terminos-y-condiciones"
//     ) {
//       return <ComingSoon />;
//     }

//     return <App Component={Component} pageProps={pageProps} {...props} />;
//   }

//   return MaintenanceApp;
// }

// // export default MyApp;
// export default process.env.NEXT_PUBLIC_PASSWORD_PROTECT === "true" ||
// process.env.PASSWORD_PROTECT === "true"
//   ? withPasswordProtect(withMaintenance(MyApp), {
//       // Options go here (optional)
//       loginApiUrl: "/api/beta/beta-login",
//       checkApiUrl: "/api/beta/beta-password-check",
//       loginComponent: LoginComponent,
//     })
//   : withMaintenance(MyApp);
export default MyApp;
