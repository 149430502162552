import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Discount {
    _id: ID!
    discountCode: String!
    purchaseIds: [String!]!
    percentOff: Float!
    createdDate: String!
    validUntil: String!
    userEmails: [String]
  }

  type Query {
    getDiscount(discountCode: String!): Discount
  }
`;

export default typeDefs;
