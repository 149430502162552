// Based on: https://github.com/vercel/next.js/tree/canary/examples/api-routes-apollo-server-and-client-auth

import { useMemo } from "react";
import fetch from "cross-fetch";
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from "@apollo/client";
// import { SchemaLink } from "@apollo/client/link/schema";
import typeDefs from "./typeDefs";
import merge from "deepmerge";
import isEqual from "lodash.isequal";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createIsomorphLink() {
  if (typeof window === "undefined") {
    // const { SchemaLink } = require("@apollo/client/link/schema");
    // // const schema = require("./schema");
    // return new SchemaLink({ schema });
    return new HttpLink({
      uri: `${process.env.NEXT_PUBLIC_ROOT_URL}/api/graphql`, // Server URL (must be absolute)
      credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
      headers: { ssr: true },
      fetch,
    });
  } else {
    return new HttpLink({
      uri: "/api/graphql",
      credentials: "same-origin",
      headers: { ssr: false },
    });
  }
}

// function createApolloClient() {
//   return new ApolloClient({
//     ssrMode: typeof window === "undefined",
//     link: createIsomorphLink(),
//     cache: new InMemoryCache(),
//   });
// }

function createApolloClient() {
  let defaultOptions;
  if (typeof window === "undefined") {
    //We don't want any cache to be stored server side
    defaultOptions = {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };
  } else {
    //We immediately show results, but check in the background if any changes occured, and eventually update the view
    defaultOptions = {
      query: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
    };
  }
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createIsomorphLink(),
    cache: new InMemoryCache(),
    typeDefs,
    defaultOptions,
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null
) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });
    // const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(initialState: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
