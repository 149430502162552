import { gql } from "apollo-server-micro";
import { VALID_PURCHASES } from "../../utils/constants";

const typeDefs = gql`
  enum PurchaseTypeEnum {
    ${Object.keys(VALID_PURCHASES)}
  }

  type Purchase {
    _id: ID!
    referenceId: String
    userId: String!
    resourceId: String
    type: PurchaseTypeEnum!
    pricePayed: Float!
    discountApplied: Float
    dateApplied: String
    createdDate: String
    resource: Vehicle
    isRenew: Boolean
    discountCodeApplied: String
    # PROPERTIES HERE
  }

  extend type Query {
    getPurchase(
      _id: ID! # PROPERTIES HERE
    ): Purchase
    getAllPurchases: [Purchase]
    getUserPurchases: [Purchase]
    # BOILERPLATER_INJECT_QUERY
  }

  extend type Mutation {
    addPurchase(
      # PROPERTIES HERE
      referenceId: String
      userId: String!
      resourceId: String
      type: PurchaseTypeEnum!
      quantity: Int!
      isRenew: Boolean
      renewType: String
      pricePayed: Float!
      discountApplied: Float
      discountCodeApplied: String
    ): Boolean
    applyPurchase(
      _id: ID! # PROPERTIES HERE
      resourceId: ID!
    ): Purchase
    deletePurchase(_id: ID!): Purchase
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
