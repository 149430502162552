/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useToast } from "../context/toast";
import { classNames } from "../utils/formatters";

const bgColors = {
  success: "bg-green-400",
  error: "bg-red-300",
  warning: "bg-amber-300",
};

const iconColors = {
  success: "text-green-800",
  error: "text-red-600",
  warning: "text-gray-800",
};

const iconComponents = {
  success: CheckCircleIcon,
  error: ExclamationCircleIcon,
  warning: ExclamationTriangleIcon,
};

export default function Toast() {
  const { state, toggleToast } = useToast();
  const { open, title, description, type } = state;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open) {
        toggleToast({ open: false });
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [open]);

  const Icon = iconComponents[type];

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                "max-w-sm w-full  shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
                bgColors[type] || "bg-white"
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {Icon ? (
                      <Icon
                        className={classNames(
                          "h-6 w-6",
                          iconColors[type] || "text-gray-800"
                        )}
                        aria-hidden="true"
                      />
                    ) : null}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-800">{description}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className=" rounded-md inline-flex text-gray-900 hover:text-gray-800 focus:outline-none "
                      onClick={() => {
                        toggleToast({ open: false });
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
