import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { ACCOUNT_STATUSES } from "../utils/constants";
import Spinner from "./Spinner";

export default function Auth({ auth, children }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const router = useRouter();
  const { status, data } = useSession();
  const { user } = data || {};

  if (auth) {
    if (status === "loading") {
      return (
        <Spinner containerClassName="h-screen flex items-center justify-center" />
      );
    } else if (status === "unauthenticated") {
      router.replace(auth.unauthorized);
      return;
    } else if (
      status === "authenticated" &&
      user?.accountStatus === ACCOUNT_STATUSES.DEACTIVATED
    ) {
      router.replace("/inactive-account");
      return;
    }
  } else {
    if (
      status === "authenticated" &&
      user?.accountStatus === ACCOUNT_STATUSES.DEACTIVATED &&
      router.pathname !== "/inactive-account"
    ) {
      router.replace("/inactive-account");
      return;
    }
  }

  return children;
}
