import React, { createContext, useState, useContext, ReactNode } from "react";

//define discount info type
interface DiscountInfoInterface {
  discountCode: string;
  percentOff: number;
}

// Define the shape of the context state
interface DiscountContextType {
  discountInfo: DiscountInfoInterface | null;
  setDiscount: (code: DiscountInfoInterface) => void;
  removeDiscount: () => void;
}

// Create a Context with default values
const DiscountContext = createContext<DiscountContextType | undefined>(
  undefined
);

// Create a provider component
interface DiscountProviderProps {
  children: ReactNode;
}

export const DiscountProvider: React.FC<DiscountProviderProps> = ({
  children,
}) => {
  const [discountInfo, setDiscountInfo] =
    useState<DiscountInfoInterface | null>(null);

  // Function to update the discount code
  const setDiscount = (code: DiscountInfoInterface) => {
    setDiscountInfo(code);
  };

  // Function to remove the discount code
  const removeDiscount = () => {
    setDiscountInfo(null);
  };

  // Context value
  const value = {
    discountInfo,
    setDiscount,
    removeDiscount,
  };

  return (
    <DiscountContext.Provider value={value}>
      {children}
    </DiscountContext.Provider>
  );
};

// Custom hook for using context
export const useDiscount = (): DiscountContextType => {
  const context = useContext(DiscountContext);
  if (context === undefined) {
    throw new Error("useDiscount must be used within a DiscountProvider");
  }
  return context;
};
