import { FACEBOOK_PAGE_URL } from "../utils/constants";
import { FacebookIcon } from "./icons";

export default function FBLink({
  onClick,
  href = FACEBOOK_PAGE_URL,
}: {
  onClick?: () => void;
  href?: string;
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="rounded-md bg-white text-sm font-medium text-blue-600 align-middle"
      onClick={onClick}
    >
      <span className="inline-flex gap-x-1 items-center ">
        <FacebookIcon className="h-6 w-6" />
        Facebook
      </span>
    </a>
  );
}
