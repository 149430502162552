/**
 *
 * GraphQL Schemas
 *
 */

import {
  gql,
  // GraphQLUpload
} from "apollo-server-micro";

/* BOILERPLATER_INJECT_IMPORT */
import Discount from "./Discount";
import HistoricPrice from "./HistoricPrice";
import Purchase from "./Purchase";
import FavoriteSearch from "./FavoriteSearch";
import LastVisited from "./LastVisited";
import Watchlist from "./Watchlist";
import Vehicle from "./Vehicle";
import User from "./User";
import Location from "./Location";

const emptySchema = gql`
  # scalar Upload
  type Query {
    _empty: String
  }
  type Mutation {
    _empty: String
  }
`;

const typeDefs = [
  emptySchema,
  User /* BOILERPLATER_INJECT_TYPEDEF */,
  Discount,
  HistoricPrice,
  Purchase,
  FavoriteSearch,
  LastVisited,
  Watchlist,
  Vehicle,
  Location,
];

export default typeDefs;
