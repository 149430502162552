import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type LastVisited {
    _id: ID!
    userId: ID!
    items: [Vehicle]
  }

  extend type Query {
    getUserLastVisited: [Vehicle]
    getLastVisiteds(query: String): [LastVisited]
    #BOILERPLATER_INJECT_QUERY
  }
  extend type Mutation {
    updateLastVisited(items: [ID]!): LastVisited
    removeItemFromLastVisited(itemId: ID!): LastVisited
    deleteLastVisited: LastVisited
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
