import React, { useState, createContext, useContext, useEffect } from "react";
import { termsAndConditions } from "../utils/termsAndConditions";
import { useSession } from "next-auth/react";
import TermsModal, { TTermsModalState } from "../components/TermsModal";
import { SUBSCRIPTION_STATUSES } from "../utils/constants";

export type TContext = {
  termsModalState?: TTermsModalState;
  termsAndConditions: {
    movi: {
      version: string;
      description: string;
    };
    connect: {
      version: string;
      description: string;
    };
  };
  toggleModal: (modalState?: TTermsModalState) => void;
};

const initialValues: TContext = {
  termsModalState: { open: false, terms: null },
  termsAndConditions,
  toggleModal: () => null,
};

const Terms = createContext(initialValues);

const TermsProvider = ({ children }: { children?: React.ReactNode }) => {
  const { status, data } = useSession();
  const { user } = data || {};

  const [termsModalState, setTermsModalState] = useState<TTermsModalState>({
    open: false,
    terms: null,
  });

  useEffect(() => {
    if (status === "authenticated" && user) {
      const acceptedMoviTerms = user.terms?.find(
        (term: any) => term.type === termsAndConditions.movi.id
      );

      const acceptedConnectTerms = user.terms?.find(
        (term: any) => term.type === termsAndConditions.connect.id
      );

      if (
        acceptedMoviTerms?.acceptedVersion !== termsAndConditions.movi.version
      ) {
        setTermsModalState({ open: true, terms: "movi" });
      } else if (
        acceptedConnectTerms?.acceptedVersion !==
          termsAndConditions.connect.version &&
        user.subscription?.type === "connect" &&
        (user?.subscription?.status === SUBSCRIPTION_STATUSES.trial ||
          user?.subscription?.status === SUBSCRIPTION_STATUSES.active)
      ) {
        setTermsModalState({ open: true, terms: "connect" });
      }
    }
  }, [user]);

  const toggleModal = (modalState: TTermsModalState) => {
    setTermsModalState(modalState);
  };

  const context = {
    termsModalState,
    termsAndConditions,
    toggleModal,
  };

  return (
    <Terms.Provider value={context}>
      {children}
      <TermsModal
        modalState={termsModalState}
        setModalState={setTermsModalState}
      />
    </Terms.Provider>
  );
};

const useTerms = () => {
  return useContext(Terms);
};

export { Terms, TermsProvider, useTerms };
