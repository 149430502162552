import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Company {
    name: String
    legalName: String
    companyId: String
    about: String
    website: String
    logo: String
    banner: String
    location: Location
  }

  type Subscription {
    type: String
    status: String
    startDate: String
    endDate: String
  }

  type Term {
    type: String
    acceptedVersion: String
  }

  input TermInput {
    type: String
    acceptedVersion: String
  }

  input SubscriptionInput {
    type: String
    status: String
    startDate: String
    endDate: String
  }

  input CompanyInput {
    name: String
    legalName: String
    companyId: String
    about: String
    website: String
    logo: String
    banner: String
    location: LocationInput
  }

  type User {
    _id: ID
    username: String
    email: String
    displayName: String
    firstName: String
    lastName: String
    secondLastName: String
    primaryPhone: String
    secondaryPhone: String
    image: String
    company: Company
    joinDate: String
    lastUpdated: String
    idNumber: String
    emailVerified: Boolean
    idVerified: Boolean
    idUrl: String
    faceWithIdUrl: String
    accountStatus: String
    subscription: Subscription
    terms: [Term]
  }

  input UserInput {
    username: String
    email: String
    displayName: String
    firstName: String
    lastName: String
    secondLastName: String
    primaryPhone: String
    secondaryPhone: String
    image: String
    company: CompanyInput
    joinDate: String
    lastUpdated: String
    idNumber: String
    emailVerified: Boolean
    idVerified: Boolean
    idUrl: String
    faceWithIdUrl: String
    accountStatus: String
    subscription: SubscriptionInput
    terms: [TermInput]
  }

  extend type Query {
    getAllUsers: [User]
    getUser(_id: ID, email: String, username: String): User
    # BOILERPLATER_INJECT_QUERY
    getConnectReferrerInfo(username: String): User
  }

  extend type Mutation {
    updateUser(user: UserInput!): User

    verifyUserId(_id: ID!, idUrl: String, faceWithIdUrl: String): User
    deactivateUserAccount(_id: ID!): User
    reactivateUserAccount(_id: ID!): User
    acceptTermsAndConditions(_id: ID!, type: String!): User
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
