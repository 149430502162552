import { INSTAGRAM_PAGE_URL } from "../utils/constants";
import { InstagramIcon } from "./icons";

export default function IGLink({
  onClick,
  href = INSTAGRAM_PAGE_URL,
}: {
  onClick?: () => void;
  href?: string;
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="rounded-md bg-white text-sm font-medium text-pink-600 align-middle"
      onClick={onClick}
    >
      <span className="inline-flex gap-x-1 items-center ">
        <InstagramIcon className="h-6 w-6 text-purple-700" />
        <span>
          <span className="text-purple-700">In</span>
          <span className="text-fuchsia-700">st</span>
          <span className="text-pink-600">ag</span>
          <span className="text-red-500">ram</span>
        </span>
      </span>
    </a>
  );
}
