const moviTermsVersion = "2023-01-01";

const moviTermsMarkdown = `## Terminos y condiciones de uso

### Version: ${moviTermsVersion}

Bienvenido al sitio web moviautos.com (el &quot;Sitio&quot;). El
acceso y uso del Sitio está sujeto a los siguientes términos y
condiciones (los &quot;Términos&quot;). Al acceder y utilizar el
Sitio, usted acepta estar sujeto a estos Términos. Si no está de
acuerdo con estos Términos, no deberá utilizar el Sitio.

El Sitio es una plataforma en línea donde las personas y los
negocios pueden registrarse y publicar automóviles para la venta.
El Sitio también permite a los usuarios pagar por destacar sus
publicaciones para aumentar su visibilidad. El Sitio utiliza un
sistema de pagos de terceros llamado GreenPay. El Sitio no guarda
la información de tarjeta de crédito de ningún usuario.

El Sitio tiene un proceso automatizado de verificación de
identidad que informa a otros usuarios si un vendedor ha
verificado o no su identidad. Sin embargo, el Sitio no se hace
responsable de ninguna estafa que ocurra en relación con una
compra o venta realizada a través del Sitio, ya que es imposible
evitar las estafas al 100%.

Toda comunicación y negociación se realiza fuera del Sitio por lo
que no está en nuestro control cualquier situación que suceda que
pueda llegar a afectar a alguna de las partes involucradas.
Para poder acceder a la información de contacto de un vendedor, es
necesario estar registrado en la aplicación pero no es obligatorio
verificar su identidad, aún así, el vendedor puede solicitar su
nombre de usuario en cualquier momento para verificar la
información.

Cualquier persona registrada que obtenga la información de
contacto de algún vendedor podrá compartir esa información con
terceros fuera del Sitio, situación sobre la que el Sitio no tiene
ningún control. Como medida, siempre sugerimos a los vendedores
verificar la información del interesado, buscando por el nombre de
usuario dentro del Sitio.

Al utilizar el Sitio, acepta que el Sitio puede requerir que
proporcione información personal, incluyendo una fotografía de su
cara y una fotografía de su cara junto a su documento de identidad
o cédula, para el proceso de verificación de identidad. Estas
fotografías no serán accesibles públicamente.

No está permitido el uso de web scrappers para extraer información
del sitio web, ya sea información pública o privada.
Usted se compromete a no utilizar el Sitio para ningún propósito
ilegal o no autorizado.

Usted acepta no interferir con o interrumpir el funcionamiento del
Sitio.

El Sitio se reserva el derecho de modificar o descontinuar el
Servicio con o sin previo aviso.

Las tarifas de cualquier servicio o producto a la venta en el
sitio pueden cambiar en cualquier momento.

El sitio se reserva el derecho de desactivar o borrar cualquier
cuenta de usuario o publicaciones realizadas por cualquier
usuario.

Si tiene preguntas sobre los terminos de uso, póngase en contacto
con nosotros a través de nuestro correo electrónico
**contacto@moviautos.com**
o número de teléfono proporcionados en nuestro sitio web.

Consultar nuestra [Política de Privacidad](https://moviautos.com/politica-de-privacidad).
`;

const connectTermsVersion = "2024-07-02";
const connectTermsMarkdown = `## Terminos y condiciones de uso (Movi Connect)

### Version: ${connectTermsVersion}

### 1. Aceptación de las Condiciones

Al acceder y utilizar la plataforma Connect, usted acepta y se compromete a cumplir con las presentes condiciones de uso. Si no está de acuerdo con alguna de estas condiciones, no utilice la plataforma.

### 2. Registro de Usuarios

Solo los vendedores de autos debidamente registrados pueden acceder y utilizar la plataforma. El registro implica proporcionar información veraz y actualizada, y mantenerla actualizada durante el uso de la plataforma.

### 3. Uso de la Plataforma

Los vendedores registrados pueden compartir sus inventarios y las comisiones que están dispuestos a compartir por la referencia de clientes.
Los usuarios se comprometen a utilizar la plataforma de manera ética y profesional.
Queda prohibido el uso de la plataforma para actividades ilícitas, fraudulentas o que violen los derechos de terceros.

### 4. Subscripción

Movi Connect es un servicio al que se accede únicamente por suscripción. Se ofrece un periodo de pruebas gratuito de máximo 90 días. El tiempo del periodo de pruebas puede cambiar en cualquier momento. Luego del periodo de pruebas se realiza un cobro mensual automáticamente. El costo de la suscripción mensual es de $35. El costo de la suscripción mensual puede cambiar en cualquier momento.

### 5. Propiedad Intelectual

Todos los derechos de propiedad intelectual sobre la plataforma y su contenido pertenecen a Collection by Movi y a Movi Autos. Los usuarios no adquieren ningún derecho de propiedad intelectual por el uso de la plataforma.

### 6. Uso de la información

Movi Autos podrá hacer uso de toda la información proporcionada por sus usuarios para la mejora del servicio y para generar información estadística. La información personal y sensible de los usuarios es sumamente importante para nosotros y no se compartirá públicamente. En cualquier momento el usuario puede contactar a Movi Autos y solicitar que toda su información sea eliminada. [Ver Política de Privacidad](https://moviautos.com/politica-de-privacidad).

### 7. Política de privacidad

La información personal proporcionada durante el registro y uso de la plataforma será tratada de acuerdo con nuestra [Política de Privacidad](https://moviautos.com/politica-de-privacidad).
El usuario acepta la Política de Privacidad de Movi Autos y se compromete a revisar dicha política periódicamente. En cualquier momento el usuario puede contactar a Movi Autos y solicitar que toda su información sea eliminada.

### 8. Limitación de Responsabilidad

Collection by Movi y Movi Autos no se responsabilizan por la exactitud o veracidad de la información compartida por los usuarios.
La plataforma actúa únicamente como un intermediario facilitador entre vendedores y compradores potenciales. Collection by Movi y Movi Autos no se hacen responsables por las transacciones realizadas entre los usuarios.

### 9. Comisiones y Pagos

Las comisiones ofrecidas por referencia de cliente deben ser claramente especificadas por los vendedores.
Collection by Movi y Movi Autos no intervienen ni garantizan el pago de las comisiones entre usuarios.

### 10. Modificaciones

Collection by Movi se reserva el derecho de modificar estas condiciones de uso en cualquier momento. Las modificaciones entrarán en vigor a partir de su publicación en la plataforma.

### 11. Terminación

Collection by Movi puede suspender o cancelar el acceso a la plataforma de cualquier usuario que incumpla estas condiciones de uso, sin previo aviso.

### 12. Protocolo de Expulsión por Incumplimiento o Falta de Ética

#### 12.1. Motivos de Expulsión

Un vendedor puede ser expulsado de la plataforma Connect por las siguientes razones:
Proporcionar información falsa o engañosa.
Incumplir las condiciones de uso de la plataforma.
Participar en actividades ilícitas o fraudulentas.
Comportamiento no ético o profesional, incluyendo, pero no limitado a, conductas que perjudiquen a otros usuarios, violen la privacidad, o infrinjan derechos de propiedad intelectual.

#### 12.2. Procedimiento de Denuncia

Cualquier usuario de la plataforma puede reportar a otro vendedor por incumplimiento o falta de ética.
Las denuncias deben presentarse por escrito a través del formulario de contacto disponible en la plataforma, proporcionando pruebas o detalles específicos del incumplimiento.

#### 12.3. Investigación

Collection by Movi llevará a cabo una investigación exhaustiva de la denuncia.
Durante la investigación, se puede solicitar información adicional tanto al denunciante como al denunciado.
Collection by Movi se reserva el derecho de suspender temporalmente el acceso del vendedor denunciado durante la investigación.

#### 12.4. Decisión

Tras la investigación, Collection by Movi tomará una decisión basada en las pruebas recopiladas.
Si se determina que el vendedor ha incumplido las condiciones de uso o ha actuado de manera no ética, se procederá a su expulsión de la plataforma.

#### 12.5. Notificación

Collection by Movi notificará por escrito al vendedor denunciado sobre la decisión tomada y, en su caso, sobre la expulsión de la plataforma.

La decisión de expulsión es definitiva y no se admiten apelaciones.

#### 12.6. Consecuencias de la Expulsión

Un vendedor expulsado perderá inmediatamente el acceso a la plataforma y todos los beneficios asociados.
Cualquier inventario o información del vendedor expulsado será eliminado de la plataforma.
El vendedor expulsado no podrá volver a registrarse en la plataforma con una nueva cuenta.

#### 13. Revisión Periódica
Collection by Movi se reserva el derecho de revisar y actualizar estas condiciones de uso y el protocolo de expulsión periódicamente, para garantizar su efectividad y adecuación a las necesidades de la comunidad.

### Disclaimers
#### 1. Exactitud de la Información
Collection by Movi y Movi Autos no garantizan la exactitud, integridad o actualidad de la información proporcionada por los usuarios en la plataforma. Los usuarios son responsables de verificar la información antes de tomar decisiones comerciales.
#### 2. Interacciones entre Usuarios
La plataforma Connect facilita la interacción entre vendedores de autos, pero no participa ni se responsabiliza de las negociaciones o acuerdos entre ellos. Cualquier disputa debe ser resuelta directamente entre las partes involucradas.
#### 3. Riesgos Asumidos por el Usuario
El uso de la plataforma implica ciertos riesgos, como la posibilidad de transacciones fallidas, información incorrecta o malentendidos. Los usuarios asumen estos riesgos al utilizar la plataforma.
#### 4. Exclusión de Garantías
La plataforma se proporciona "tal cual" y "según disponibilidad". Collection by Movi y Movi Autos no ofrecen garantías explícitas o implícitas sobre el funcionamiento de la plataforma o los resultados obtenidos de su uso.
#### 5. Limitación de Responsabilidad
En ningún caso, Collection by Movi y Movi Autos serán responsables por daños directos, indirectos, incidentales, especiales o consecuentes derivados del uso o la imposibilidad de uso de la plataforma.
#### 6. Ley Aplicable
Estas condiciones de uso y disclaimers se rigen por las leyes del país en el que operan Collection by Movi y Movi Autos. Cualquier disputa será sometida a la jurisdicción de los tribunales competentes de dicho país.
#### 7. Protocolo de Expulsión
Collection by Movi se reserva el derecho de expulsar a cualquier vendedor que incumpla las condiciones de uso o actúe de manera no ética, siguiendo el protocolo de expulsión detallado en las condiciones de uso. La expulsión es definitiva y no admite apelaciones.

Estas condiciones y disclaimers están diseñados para proteger a Collection by Movi y Movi Autos, asegurando un uso adecuado de la plataforma y estableciendo las expectativas y responsabilidades de los usuarios. Es recomendable revisar estos documentos con un abogado especializado en derecho comercial y tecnológico para asegurarse de que cumplen con todas las normativas y requisitos legales aplicables.

`;

export const termsAndConditions = {
  movi: {
    id: "movi",
    version: moviTermsVersion,
    description: "Terminos y condiciones generales de moviautos.com",
    content: moviTermsMarkdown,
  },
  connect: {
    id: "connect",
    version: connectTermsVersion,
    description: "Terminos y condiciones del servicio de Movi Connect",
    content: connectTermsMarkdown,
  },
};
