import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type FavoriteSearch {
    _id: ID!
    userId: ID
    query: String!
    filters: VehicleFiltersType!
    createdDate: String
    lastVisited: String
    notify: Boolean
  }

  extend type Query {
    getFavoriteSearch(
      _id: ID! # PROPERTIES HERE
    ): FavoriteSearch
    getUserFavoriteSearches: [FavoriteSearch]
    # BOILERPLATER_INJECT_QUERY
    getFavoriteSearchesByVehicle(vehicle: VehicleInput): [FavoriteSearch]
  }

  extend type Mutation {
    addFavoriteSearch(
      # PROPERTIES HERE
      query: String!
      notify: Boolean
    ): FavoriteSearch
    deleteFavoriteSearch(_id: ID!): FavoriteSearch
    # BOILERPLATER_INJECT_MUTATION
    updateFavoriteSearch(
      # PROPERTIES HERE
      _id: ID!
      notify: Boolean
    ): FavoriteSearch
    deleteAllFavoriteSearches: Boolean
  }
`;

export default typeDefs;
