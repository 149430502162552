import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Location {
    country: String
    province: String
    municipality: String
    address: String
  }

  input LocationInput {
    country: String
    province: String
    municipality: String
    address: String
  }
`;

export default typeDefs;
