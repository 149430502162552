import React, { useState, createContext, useContext } from "react";

type TState = {
  open?: boolean;
  title?: string;
  description?: string;
  type?: string;
};

export type TContext = {
  state?: TState;
  toggleToast: (state?: TState) => void;
};

const initialValues: TContext = {
  state: {
    open: false,
  },
  toggleToast: () => null,
};

const Toast = createContext(initialValues);

const ToastProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, setState] = useState({ open: false });

  const toggleToast = (state) => {
    setState(state);
  };

  const context = {
    state,
    toggleToast,
  };

  return <Toast.Provider value={context}>{children}</Toast.Provider>;
};

const useToast = () => {
  return useContext(Toast);
};

export { Toast, ToastProvider, useToast };
